import React, { useState } from 'react';

export default function TechGuides() {
    const [currentPDF, setCurrentPDF] = useState(null);

    // List of PDF files
    const pdfFiles = [
        { name: 'Technical Guide 2022', filePath: '/guides/Technical_Guide_2022.pdf' },
    ];

    return (
        <div
            style={{
                padding: '20px',
                fontFamily: 'Arial, sans-serif',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100vh',
                fontSize: '1.5rem',
            }}
        >
            <h2>PDF Viewer</h2>

            {/* Show list of PDF files */}
            {!currentPDF && (
                <div>
                    <h3>Select a PDF to View:</h3>
                    {pdfFiles.map((pdf, index) => (
                        <button
                            key={index}
                            onClick={() => setCurrentPDF(pdf.filePath)}
                            style={{
                                margin: '10px',
                                padding: '15px',
                                backgroundColor: '#008CBA',
                                color: 'white',
                                border: 'none',
                                cursor: 'pointer',
                                fontSize: '1.2rem',
                                borderRadius: '5px',
                            }}
                        >
                            {pdf.name}
                        </button>
                    ))}
                </div>
            )}

            {/* Display selected PDF */}
            {currentPDF && (
                <div style={{ width: '100%', height: '100%', textAlign: 'center' }}>
                    <button
                        onClick={() => setCurrentPDF(null)}
                        style={{
                            marginBottom: '20px',
                            padding: '10px 20px',
                            backgroundColor: '#f44336',
                            color: 'white',
                            border: 'none',
                            cursor: 'pointer',
                            fontSize: '1rem',
                            borderRadius: '5px',
                        }}
                    >
                        Back to List
                    </button>
                    <iframe
                        src={currentPDF}
                        title="PDF Viewer"
                        style={{
                            width: '80%',
                            height: '80vh',
                            border: '1px solid #ccc',
                            borderRadius: '5px',
                        }}
                    ></iframe>
                </div>
            )}
        </div>
    );
}
