import React, { useState } from 'react';

export default function Troubleshooting() {
    const [currentStep, setCurrentStep] = useState(0);
    const [responses, setResponses] = useState({});

    // Editable questions array
    const questions = [
        {
            title: 'Where is the issue occurring?',
            field: 'location',
            options: ['Studio A', 'Studio B (Podcasts)', 'Nerve Office', 'Online Stream', 'On Campus Stream']
        },
        {
            title: 'What category does the issue fall into?',
            field: 'category',
            options: []
        },
        {
            title: 'What type of issue is it?',
            field: 'type',
            options: ['No sound', 'Distorted audio', 'Microphone not working', 'Stream offline']
        }
    ];

    // Editable troubleshooting steps based on answers
    const troubleshootingSteps = [
        // Studio A
        { location: 'Studio A', category: 'Presenter Mic', type: 'Microphone not working', steps: ['1. Check if the mic is plugged in.', '2. Ensure the mic is unmuted (physical switch on some mics).', '3. Ensure the relevant fader is turned on and up', '4. Try turn up the trim dial so see if it does anything.'] },
        { location: 'Studio A', category: 'Guest Mic', type: 'Microphone not working', steps: ['1. Check if the mic is plugged in.', '2. Ensure the mic is unmuted (physical switch on some mics).', '3. Ensure the relevant fader is turned on and up', '4. Try turn up the trim dial so see if it does anything.'] },
        { location: 'Studio A', category: 'Presenter Headphones', type: 'Headphones not working', steps: ['1. Check the headphone dial is turned up on the sound desk. ', '2. Ensure the headphones are plugged into the sound desk (control room output).'] },
        { location: 'Studio A', category: 'Guest Headphones', type: 'Headphones not working', steps: ['1. Check the headphone dial is turned up on the sound desk', '2. Check the headphone dials are up on the labelled red box (to the left of the presenters chair, underneath the phone)'] },
        { location: 'Studio A', category: 'Speakers', steps: ['1. Check the speaker dial is turned up on the sound desk', '2. Check the speaker dial is turned up on the right hand speaker'] },
        { location: 'Studio A', category: 'Myriad', type: 'Playing from the wrong hour', steps: ['1. Make sure Myriad is on "Live" mode!', '2. Press the "Power off" button (green button above "home" on the right side)', '3. Eject every song playing', '4. Power it back on!'] },
        { location: 'Studio A', category: 'Myriad', type: 'Skipping songs', steps: ['1. Make sure Myriad is on "Live" mode!', '2. Ensure you are looking at the correct date and hour.'] },
        { location: 'Studio A', category: 'Myriad', type: 'Other', steps: ['1. Make sure Myriad is on "Live" mode!', '2. Drop a message in the WhatsApp group if you are still stuck.'] },
        // Office
        { location: 'Nerve Office', category: 'Microphone', type: 'Microphone not working', steps: ['1. Ensure the relevant fader is turned on and up!', '2. Try adjusting the trim dial at the top of the sound desk.'] },
        { location: 'Nerve Office', category: 'PC', type: 'Freezing / Slow', steps: ['1. Try closing and re-opening Myriad.', '2. Restart the PC. It will automatically log in and load Myriad again, just give it a minute!'] },
        { location: 'Nerve Office', category: 'Myriad', type: 'Frozen', steps: ['1. Try closing and re-opening Myriad.', '2. Restart the PC. It will automatically log in and load Myriad again, just give it a minute!'] },
        // Online Stream
        { location: 'Online Stream', category: 'No sound', steps: ['1. Ensure the player faders are turned up in the studio', '2. Check if there is audio in the studio speakers / headphones.', '3. Check if the speakers in the SUBU building are working.'] },
        { location: 'Online Stream', category: 'Website offline', steps: ['1. Check it on another device to see if it works', '2. Switch WiFi networks or to mobile data to see if that works', '3. Contact the Head of Website if it still doesn\'t work'] },
        { location: 'Online Stream', category: 'Error message when playing the stream', steps: ['1. Try on another device or WiFi network.', '2. Contact the Head of Website as soon as possible!'] },
        // Studio B
        // TODO at a later date
        //{ location: 'Studio B', category: '', type: '', steps: ['1. '] },
        // On Campus Stream
        { location: 'On Campus Stream', category: 'SUBU speakers not working', steps: ['1. Check the speakers and headphones in the studio are working.', '2. Check the online stream is working.', '3. Contact the Head Of Website / Head of Tech.'] },

    ];

    // Locations and categories that DO NOT need a "Type" selection
    const skipTypeSelection = [
        { location: 'Online Stream', category: 'No sound' },
        { location: 'Online Stream', category: 'Website offline' },
        { location: 'Online Stream', category: 'Error message when playing the stream'},
        { location: 'On Campus Stream', category: 'SUBU speakers not working' },
        { location: 'Studio A', category: 'Speakers'},
        { location: 'Nerve Office', category: 'Microphone'},
    ];

    // Handle user's answer to each question
    const handleAnswer = (answer) => {
        const questionField = questions[currentStep].field;
        const newResponses = { ...responses, [questionField]: answer };

        setResponses(newResponses);

        // Check if this location and category combo should skip "Type"
        if (questionField === 'category') {
            const shouldSkipType = skipTypeSelection.some(
                (entry) => entry.location === newResponses.location && entry.category === answer
            );

            if (shouldSkipType) {
                findTroubleshootingSteps(newResponses);
                return;
            }
        }

        // Move to next question if there are more questions
        if (currentStep < questions.length - 1) {
            setCurrentStep(currentStep + 1);
        } else {
            findTroubleshootingSteps(newResponses);
        }
    };

    const findTroubleshootingSteps = (answers) => {
        // Find relevant troubleshooting steps based on the user's answers
        const result = troubleshootingSteps.find((step) =>
            step.location === answers.location &&
            step.category === answers.category &&
            (step.type ? step.type === answers.type : true) // Ignore type if it's not defined
        );

        setResponses((prevResponses) => ({
            ...prevResponses,
            troubleshooting: result ? result.steps : ['No specific troubleshooting steps found. Please contact support.']
        }));
    };


    // Get filtered options based on previous answers
    const getFilteredOptions = () => {
        const location = responses.location;

        if (currentStep === 1) {
            // Dynamically filter categories based on location
            if (location === 'Studio A') {
                return ['Myriad', 'Presenter Mic', 'Guest Mic', 'Presenter Headphones', 'Guest Headphones', 'Speakers'];
            } else if (location === 'Studio B (Podcasts)') {
                return ['Microphones', 'Headphones', 'Mixer / Recorder'];
            } else if (location === 'Nerve Office') {
                return ['Myriad', 'Microphone', 'PC'];
            } else if (location === 'Online Stream') {
                return ['No sound', 'Website offline', 'Error message when playing the stream'];
            } else if (location === 'On Campus Stream') {
                return ['SUBU speakers not working'];
            }
        }

        if (currentStep === 2) {
            // Filter types based on category
            const category = responses.category;
            if (category === 'Audio') {
                return ['No sound', 'Distorted audio'];
            } else if (category === 'Presenter Mic' || category === 'Guest Mic' || category === 'Microphone') {
                return ['Microphone not working'];
            } else if (category === 'Streaming') {
                return ['Stream offline'];
            } else if (category === 'Presenter Headphones' || category === 'Guest Headphones') {
                return ['Headphones not working'];
            } else if (category === 'Myriad' && location === 'Studio A') {
                return ['Playing from the wrong hour', 'Skipping songs', 'Other'];
            } else if (category === 'PC') {
                return ['Freezing / Slow'];
            } else if (category === 'Myriad' && location === 'Nerve Office') {
                return ['Frozen'];
            }
        }

        return questions[currentStep]?.options || [];
    };

    // Reset troubleshooting process
    const handleRestart = () => {
        setCurrentStep(0);
        setResponses({});
    };

    return (
        <div style={{
            padding: '20px',
            fontFamily: 'Arial, sans-serif',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            flexDirection: 'column',
            fontSize: '1.5rem'
        }}>
            <h2>Troubleshooting Guide</h2>

            {/* Show questions */}
            {currentStep < questions.length && !responses.troubleshooting && (
                <div>
                    <h3>{questions[currentStep].title}</h3>
                    {getFilteredOptions().map((option, index) => (
                        <button
                            key={index}
                            onClick={() => handleAnswer(option)}
                            style={{
                                margin: '10px',
                                padding: '15px',
                                backgroundColor: '#008CBA',
                                color: 'white',
                                border: 'none',
                                cursor: 'pointer',
                                fontSize: '1.2rem',
                                borderRadius: '5px'
                            }}
                        >
                            {option}
                        </button>
                    ))}
                </div>
            )}

            {/* Start Again Button */}
            <button
                onClick={handleRestart}
                style={{
                    marginTop: '20px',
                    padding: '10px 20px',
                    fontSize: '1.2rem',
                    cursor: 'pointer',
                    backgroundColor: '#dc3545',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px'
                }}
            >
                Start Again
            </button>

            {/* Show troubleshooting steps after all questions are answered */}
            {responses.troubleshooting && (
                <div style={{
                    padding: '10px',
                    backgroundColor: 'lightgreen',
                    borderRadius: '5px',
                    marginTop: '20px'
                }}>
                    <h4>Suggested Troubleshooting:</h4>
                    <div style={{
                        textAlign: 'left',
                        fontSize: '1.2rem',
                        margin: '0'
                    }}>
                        {responses.troubleshooting.map((step, index) => (
                            <p key={index}>{step}</p>
                        ))}
                    </div>

                    {/* Contact support message */}
                    <div style={{
                        marginTop: '20px',
                        padding: '10px',
                        backgroundColor: 'lightcoral',
                        borderRadius: '5px'
                    }}>
                        <h4>If these steps do not resolve the issue:</h4>
                        <p>Please contact the committee in the relevant WhatsApp group (daytimes/evenings) for further assistance.</p>
                    </div>
                </div>
            )}
        </div>
    );
}
